import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
var initialState = {
  consented: 0,
  consentDate: null,
  categories: {
    personal: 0,
    analytics: 0
  },
  status: 'init'
};
var cookieSlice = createSlice({
  name: 'cookie',
  initialState: initialState,
  reducers: {
    loadCookie: function loadCookie(state, action) {
      var _action$payload = action.payload,
          consented = _action$payload.consented,
          consentDate = _action$payload.consentDate,
          categories = _action$payload.categories;
      state.consented = consented;
      state.consentDate = consentDate;
      state.categories = categories;
      state.status = 'success';
    }
  }
});
var loadCookie = cookieSlice.actions.loadCookie;
export { loadCookie };
export default cookieSlice.reducer;